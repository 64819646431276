@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-50: #eff6ff;
  --primary-100: #dbeafe;
  --primary-200: #bfdbfe;
  --primary-300: #93c5fd;
  --primary-400: #60a5fa;
  --primary-500: #3b82f6;
  --primary-600: #2563eb;
  --primary-700: #1d4ed8;
  --primary-800: #1e40af;
  --primary-900: #1e3a8a;

  --gray-50: #f9fafb;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-400: #9ca3af;
  --gray-500: #6b7280;
  --gray-600: #4b5563;
  --gray-700: #374151;
  --gray-800: #1f2937;
  --gray-900: #111827;
}

/* Custom scrollbar styling */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--gray-300);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--gray-400);
}

/* Base styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--gray-50);
  color: var(--gray-800);
}

/* Animation classes */
.animate-fade-in {
  animation: fade-in 0.15s ease-out forwards;
}

.animate-fade-out {
  animation: fade-out 0.15s ease-out forwards;
}

/* Utility classes */
.break-words {
  word-break: break-word;
}

.min-h-screen {
  min-height: 100vh;
}

/* Message bubble styles */
.message-bubble {
  max-width: 70%;
  word-wrap: break-word;
  border-radius: 0.75rem;
  padding: 0.75rem 1rem;
  position: relative;
  margin-bottom: 0.5rem;
}

.message-bubble.incoming {
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border: 1px solid var(--gray-200);
  border-bottom-left-radius: 0;
}

.message-bubble.outgoing {
  background-color: var(--primary-500);
  color: white;
  border-bottom-right-radius: 0;
  margin-left: auto;
}

/* Media preview styles */
.media-preview {
  max-height: 300px;
  object-fit: contain;
  border-radius: 0.5rem;
  overflow: hidden;
}

/* Form styling */
.form-input {
  @apply w-full rounded-lg border border-gray-300 px-4 py-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500/30;
}

.form-select {
  @apply rounded-lg border border-gray-300 px-4 py-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500/30;
}

.form-checkbox {
  @apply h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500;
}

.form-radio {
  @apply h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-500;
}

.form-label {
  @apply mb-1 block text-sm font-medium text-gray-700;
}

/* Auth specific styles */
.auth-container {
  @apply min-h-screen flex items-center justify-center bg-gray-50 p-4;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='152' height='152' viewBox='0 0 152 152'%3E%3Cg fill-opacity='0.03' fill='%233b82f6'%3E%3Cpath d='M152 150v2H0v-2h28v-8H8v-20H0v-2h8V80h42v20h20v42H30v8h90v-8H80v-42h20V80h42v40h8V30h-8v40h-42V50H80V8h40V0h2v8h20v20h8V0h2v150zm-2 0v-28h-8v20h-20v8h28zM82 30v18h18V30H82zm20 18h20v20h18V30h-20V10H82v18h20v20zm0 2v18h18V50h-18zm20-22h18V10h-18v18zm-54 92v-18H50v18h18zm-20-18H28V82H10v38h20v20h38v-18H48v-20zm0-2V82H30v18h18zm-20 22H10v18h18v-18zm54 0v18h38v-20h20V82h-18v20h-20v20H82zm18-20H82v18h18v-18zm2-2h18V82h-18v18zm20 40v-18h18v18h-18zM30 0h-2v8H8v20H0v2h8v40h42V50h20V8H30V0zm20 48h18V30H50v18zm18-20H48v20H28v20H10V30h20V10h38v18zM30 50h18v18H30V50zm-2-40H10v18h18V10z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}

.auth-card {
  @apply bg-white py-8 px-6 shadow-lg rounded-xl border border-gray-100;
  backdrop-filter: blur(10px);
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.05), 0 8px 10px -6px rgba(0, 0, 0, 0.05);
}

.auth-logo {
  @apply mx-auto h-16 w-16 flex items-center justify-center rounded-full;
  background: linear-gradient(135deg, #3b82f6 0%, #8b5cf6 100%);
  box-shadow: 0 10px 15px -3px rgba(59, 130, 246, 0.3);
}

.auth-input-icon {
  @apply absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-400;
}

.auth-button {
  @apply w-full flex justify-center items-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white transition-all duration-200;
  background: linear-gradient(135deg, #3b82f6 0%, #8b5cf6 100%);
}

.auth-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(59, 130, 246, 0.3);
  background: linear-gradient(135deg, #2563eb 0%, #7c3aed 100%);
}

.auth-button:disabled {
  @apply opacity-70 cursor-not-allowed;
  transform: none;
  box-shadow: none;
}

.auth-error {
  @apply mb-6 p-4 bg-red-50 border-l-4 border-red-500 rounded-md flex items-start;
  animation: shake 0.4s 1 linear;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-5px);
  }

  100% {
    transform: translateX(0);
  }
}

/* Transitions */
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

/* Button hover effect */
.button-hover-lift {
  transition: transform 0.15s ease;
}

.button-hover-lift:hover {
  transform: translateY(-1px);
}

/* Toast animation keyframes */
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

/* Card hover effects */
.card-hover {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.card-hover:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

/* Gradient backgrounds */
.bg-gradient-primary {
  background: linear-gradient(to right, var(--primary-500), var(--primary-600));
}

.bg-gradient-blue-purple {
  background: linear-gradient(to right, #3b82f6, #8b5cf6);
}

.text-gradient {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to right, var(--primary-500), #8b5cf6);
}

/* Simplified grid layouts */
.grid-layout-dashboard {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
}

/* Modal backdrop */
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
}

/* Focus styles */
*:focus-visible {
  outline: 2px solid var(--primary-500);
  outline-offset: 2px;
}

/* Badge styles */
.badge {
  @apply inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium;
}

.badge-primary {
  @apply bg-blue-100 text-blue-800;
}

.badge-success {
  @apply bg-green-100 text-green-800;
}

.badge-warning {
  @apply bg-yellow-100 text-yellow-800;
}

.badge-danger {
  @apply bg-red-100 text-red-800;
}

/* Custom shadow variations */
.shadow-card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1);
}

.shadow-dropdown {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

/* Media Viewer Animations */
.media-modal-enter {
  opacity: 0;
  transform: scale(0.95);
}

.media-modal-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 200ms ease-out, transform 200ms ease-out;
}

.media-modal-exit {
  opacity: 1;
  transform: scale(1);
}

.media-modal-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 150ms ease-in, transform 150ms ease-in;
}

.media-backdrop-enter {
  opacity: 0;
}

.media-backdrop-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-out;
}

.media-backdrop-exit {
  opacity: 1;
}

.media-backdrop-exit-active {
  opacity: 0;
  transition: opacity 150ms ease-in;
}